import React, {useState} from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql, Link } from 'gatsby'

import '../styles/global.scss'

const Layout = ({ children }) => {
  
  const [toggleNav, setToggleNav] = useState(false)
  return(
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
<div className={`site-wrapper ${toggleNav ? `site-head-open` : ``}`}>
      <header className="site-head">
        <div className="site-head-container">
          <a
            className="nav-burger"
            href={`#`}
            onClick={() => setToggleNav(!toggleNav)}
          >
            <div
              className="hamburger hamburger--collapse"
              aria-label="Menu"
              role="button"
              aria-controls="navigation"
            >
              <div className="hamburger-box">
                <div className="hamburger-inner" />
              </div>
            </div>
          </a>
          <nav class="site-head-left">
            <ul className="nav" role="menu">
              <li className="nav-home" role="menuitem">
                <Link to={`/`} activeClassName="nav-current">Home</Link>
              </li>
              <li className="nav-about" role="menuitem">
                <Link to={`/about`} activeClassName="nav-current">About</Link>
              </li>
              <li className="nav-elements" role="menuitem">
                <Link to={`/portfolio`} activeClassName="nav-current">Portfolio</Link>
              </li>
            </ul>
          </nav>
          <div className="site-head-center">
            <Link className="site-head-logo" to={`/`}>
              {data.site.siteMetadata.title}
            </Link>
          </div>
          <div className="site-head-right">
            <div className="social-links">
              <a
                href="https://www.instagram.com"
              >
                Instagram
              </a>
              <a
                href="https://twitter.com"
              >
                Twitter
              </a>
            </div>
          </div>
        </div>
      </header>
      <main id="site-main" className="site-main">
        <div id="swup" className="transition-fade">
          {children}
        </div>
      </main>
      <footer className="site-foot">
        &copy; {new Date().getFullYear()} <Link to={`/`}>{data.site.siteMetadata.title}</Link> &mdash;
        Built by{" "}
        <a
          href="https://alfie-jones.com"
        >
          Alfie Jones
        </a>
      </footer>
    </div>
    )}
  />
)}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
